import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import send from '@icon/send.webp';
import chat from '@icon/chat.webp';
import useTawkTo from '@hook/useTawkTo.hook';

const Widget = ({ title, description, icon, btnText, onClick }: any) => {
  return (
    <Stack
      p={{ md: 5, xs: 2 }}
      pb={{ md: 7, xs: 3 }}
      bgcolor="common.white"
      maxWidth={{ md: '570px', xs: 'none' }}
      width="100%"
      gap={2}
      border="1px solid"
      borderColor="primary.50"
      borderRadius={2}
    >
      <img src={icon} alt="" width={40} height="auto" />
      <Typography variant="h4" fontWeight={500}>
        {title}
      </Typography>
      <Typography color="textSecondary" mb={2}>
        {description}
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClick}
          size="large"
        >
          {btnText || 'FAQ Page'}
        </Button>
      </Box>
    </Stack>
  );
};

const Widgets = () => {
  const { maximize } = useTawkTo();

  const WIDGETS = [
    {
      icon: chat,
      title: 'Common Asked Questions',
      description: 'Visiting our FAQs is the fastest way to find support.',
      onClick: () => {
        //
      },
    },
    {
      icon: send,
      title: 'Help & Support',
      description: (
        <>
          <span>Reach us via Email:support@zinari.io</span>
          <br />
          <span> Support Hours: 24 hours a day, Mon to Fri</span>
        </>
      ),
      onClick: maximize,
      btnText: 'Chat with us',
    },
  ];
  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        background: 'rgba(223, 239, 249, 0.20)',
        py: { md: 10, xs: 5 },
      }}
    >
      <Stack
        gap={2}
        justifyContent="space-between"
        direction={{ md: 'row', xs: 'column' }}
      >
        {WIDGETS.map((widget) => (
          <Widget key={widget.title} {...widget} />
        ))}
      </Stack>
    </Box>
  );
};

export default Widgets;
