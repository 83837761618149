import React from 'react';

import Helmet from '@component/Helmet';
import ReadyToGetStarted from '@component/ReadyToGetStarted';

import CommonQuestions from './components/CommonQuestions';
import Hero from './components/Hero';
import FeeSection from './components/FeeSection';
import Withdrawals from './components/Withdrawals';

const PricingTable: React.FC = () => {
  return (
    <>
      <Helmet
        title="Pricing"
        description="Explore ZinariPay's competitive pricing for secure and scalable cryptocurrency payment solutions. Understand our fee structure and discover how our transparent pricing can benefit your business."
        keywords="ZinariPay pricing, cryptocurrency payment fees, blockchain transaction costs, fintech pricing, transparent pricing"
      />
      <Hero />
      <FeeSection />
      <Withdrawals />
      <CommonQuestions />
      <ReadyToGetStarted />
    </>
  );
};

export default PricingTable;
