import { Box, Stack, Typography } from '@mui/material';

import developers from '@image/developers.webp';

const Hero = () => {
  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      gap={2}
      sx={{
        height: { lg: '100vh', xs: 'fit-content' },
        maxHeight: { lg: '550px', xs: 'none' },
      }}
    >
      <Box
        sx={{
          pr: 2,
          pl: { lg: '130px', xs: 2 },
          maxWidth: { md: '730px', xs: 'none' },
        }}
      >
        <Typography variant="h2" fontWeight={700} textAlign="left" mb={5}>
          An all-in-one API suite for exceptional{' '}
          <Box component="span" sx={{ color: 'secondary.400' }}>
            payment
          </Box>{' '}
          solutions.
        </Typography>
        <Typography lineHeight="27px" mb={10}>
          Allowing businesses to connect with customers worldwide. With our
          platform, you can easily accept and process transactions in various
          currencies, ensuring a smooth and efficient payment experience for
          everyone.
        </Typography>
      </Box>

      <Box
        width="fill-available"
        sx={{
          px: { xs: 2, lg: 0 },
          background: `url(${developers}) no-repeat center center`,
          backgroundSize: 'contain',
          height: { xs: '300px', lg: '480px' },
        }}
      />
    </Stack>
  );
};

export default Hero;
