import React from 'react';
import { Box } from '@mui/material';

import Helmet from '@component/Helmet';
import Content from '@page/AntiPolicy/Content';

const AntiPolicy = () => (
  <Box>
    <Helmet
      title="Anti-Bribery, Anti-Corruption, and Anti-Money Laundering Policy"
      description="Read ZinariPay's commitment to upholding ethical practices with our Anti-Bribery, Anti-Corruption, and Anti-Money Laundering policy. Learn how we safeguard integrity in our operations."
      keywords="Anti-Bribery policy, Anti-Corruption policy, Anti-Money Laundering policy, ethical practices, compliance, ZinariPay"
    />

    <Content />
  </Box>
);

export default AntiPolicy;
