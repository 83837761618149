import { buttonClasses, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      50: '#dfeff9',
      100: '#bed6e0',
      200: '#9cb9c6',
      300: '#799dad',
      400: '#5f8799',
      500: '#437387',
      600: '#386576',
      700: '#2a5160',
      800: '#1d3f4c',
      900: '#0c2a35',
      A100: '#bed6e0',
      A200: '#9cb9c6',
      A400: '#5f8799',
      A700: '#2a5160',
    },
    secondary: {
      50: '#e4f9ec',
      100: '#beeed1',
      200: '#91e4b4',
      300: '#5ad995',
      400: '#0fd07c',
      500: '#00c664',
      600: '#00b659',
      700: '#00a34c',
      800: '#00923f',
      900: '#00712a',
      A100: '#beeed1',
      A200: '#91e4b4',
      A400: '#0fd07c',
      A700: '#00a34c',
    },
    error: {
      50: '#ffebee',
      100: '#ffcdd2',
      200: '#ef9a9a',
      300: '#e57373',
      400: '#ef5350',
      500: '#f44336',
      600: '#e53935',
      700: '#d32f2f',
      800: '#c62828',
      900: '#b71c1c',
      A100: '#ffcdd2',
      A200: '#ef9a9a',
      A400: '#ef5350',
      A700: '#d32f2f',
    },
    warning: {
      50: '#fff3e0',
      100: '#ffe0b2',
      200: '#ffcd80',
      300: '#ffb84d',
      400: '#ffa826',
      500: '#ff9900',
      600: '#fb8d00',
      700: '#f57d00',
      800: '#ef6d00',
      900: '#e65200',
      A100: '#ffe0b2',
      A200: '#ffcd80',
      A400: '#ffa826',
      A700: '#f57d00',
    },
    success: {
      50: '#e8f5e9',
      100: '#c8e6c9',
      200: '#a5d6a7',
      300: '#81c784',
      400: '#66bb6a',
      500: '#4caf50',
      600: '#43a047',
      700: '#388e3c',
      800: '#2e7d32',
      900: '#1b5e20',
      A100: '#c8e6c9',
      A200: '#a5d6a7',
      A400: '#66bb6a',
      A700: '#388e3c',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    text: {
      primary: '#212121',
      secondary: '#424242',
      disabled: '#bdbdbd',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#212121',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h1: { fontFamily: 'Outfit, serif' },
    h2: { fontFamily: 'Outfit, serif' },
    h3: { fontFamily: 'Outfit, serif' },
    h4: { fontFamily: 'Outfit, serif' },
    h5: { fontFamily: 'Outfit, serif' },
    h6: { fontFamily: 'Outfit, serif' },
    subtitle1: { fontFamily: 'Outfit, serif' },
    subtitle2: { fontFamily: 'Outfit, serif' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
            color: '#FFF',
          },
          [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}`]: {
            background: '#0c2a35',
          },
        },
      },
    },
  },
});

export default theme;
