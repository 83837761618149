import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';

import story from '@image/story.webp';

const Navigation = ({ isDisabled, left, onClick }: any) => {
  const Icon = left ? ChevronLeftRounded : ChevronRightRounded;

  return (
    <Box
      height={'64px'}
      width={'64px'}
      borderRadius={'100%'}
      border="1px solid"
      borderColor="primary.900"
      bgcolor={isDisabled ? 'transparent' : 'primary.900'}
      alignItems="center"
      justifyContent="center"
      display="flex"
      onClick={onClick}
      sx={{
        '&:hover': {
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        },
      }}
    >
      <Icon
        fontSize={'large'}
        sx={{
          color: isDisabled ? 'primary.900' : 'common.white',
        }}
      />
    </Box>
  );
};

const DESCRIPTIONS = [
  <>
    <Typography variant="body1" color="textSecondary" mt={3} mb={4}>
      Founded with the goal of simplifying cryptocurrency transactions for
      businesses, our journey began with a small team of passionate individuals
      who saw the potential of blockchain technology to transform the financial
      landscape.
    </Typography>
    <Typography variant="body1" color="textSecondary" mt={3} mb={4}>
      We started by identifying the key pain points faced by businesses in
      managing and processing cryptocurrency payments. Through relentless
      innovation and a commitment to security, we developed a platform that
      addresses these challenges while providing a seamless and scalable
      solution.
    </Typography>
  </>,
  <Typography variant="body1" color="textSecondary" mt={3} mb={4} key="page2">
    From our initial idea to our upcoming launch, we have focused on building a
    product that empowers businesses to operate with confidence in the world of
    digital currencies. Our journey is just beginning, and we are excited to
    continue growing and evolving alongside our clients.
  </Typography>,
];

const OurStory = () => {
  const [selectedDescription, setSelectedDescription] = useState(0);
  const hasMorePages = selectedDescription < DESCRIPTIONS.length - 1;

  const goToNextPage = useCallback(() => {
    if (hasMorePages) setSelectedDescription((value) => value + 1);
  }, [hasMorePages]);

  const goToPrevPage = useCallback(() => {
    if (selectedDescription !== 0) setSelectedDescription((value) => value - 1);
  }, [selectedDescription]);

  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        py: { lg: 15, xs: 5 },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        gap={10}
      >
        <Box maxWidth={{ md: '551px', xs: 'none' }}>
          <img src={story} alt={'sale lock'} width="100%" height="auto" />
        </Box>
        <Box maxWidth={{ md: '577px', xs: 'none' }}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Our Story
          </Typography>

          <Typography variant="h4" fontWeight={600}>
            Revolutionizing cryptocurrency transactions with secure, global
            integration.
          </Typography>

          {DESCRIPTIONS[selectedDescription]}

          <Stack direction="row" gap={3}>
            <Navigation
              isDisabled={selectedDescription === 0}
              left
              onClick={goToPrevPage}
            />
            <Navigation isDisabled={!hasMorePages} onClick={goToNextPage} />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default OurStory;
