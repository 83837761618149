import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const Withdrawals = () => {
  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        sx={{ py: 6, mb: 15, px: 5 }}
        bgcolor="primary.900"
        borderRadius={2}
        justifyContent="space-between"
      >
        <Typography color="common.white" variant="h4" fontWeight={700}>
          3 USDT/USDC
        </Typography>
        <Typography color="common.white" variant="h5" fontWeight={500}>
          Per withdrawal
        </Typography>
      </Stack>
    </Box>
  );
};

export default Withdrawals;
