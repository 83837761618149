import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material';

import create from '@icon/create.webp';
import kyc from '@icon/kyc.webp';
import platform from '@icon/platform.webp';
import wallet from '@icon/wallet.webp';

const VALUES = [
  {
    title: 'Create an Account',
    description:
      'Sign up in minutes and start setting up your payment gateway.',
    icon: create,
  },
  {
    title: 'Integrate with Your Platform',
    description:
      'Easily integrate using our CDN or NPM packages, with full developer support.',
    icon: platform,
  },
  {
    title: 'Complete KYC',
    description:
      'Verify your business identity with our streamlined KYC process',
    icon: kyc,
  },
  {
    title: 'Start Receiving Payments',
    description:
      'Begin accepting cryptocurrency payments and enjoy fast, secure transactions.',
    icon: wallet,
  },
];

const GetStarted = () => {
  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        py: { lg: 15, xs: 5 },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        gap={6}
      >
        <Box sx={{ maxWidth: { md: '450px', xs: 'none', width: '100%' } }}>
          <Typography fontSize="18px" color="grey.800" mb={3}>
            How to get started
          </Typography>
          <Typography variant="h4" fontWeight={500}>
            Start accepting cryptocurrency{' '}
            <Box component="span" sx={{ color: 'secondary.400' }}>
              payments
            </Box>{' '}
            in just a few simple steps.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {VALUES.map(({ title, description, icon }, index) => (
            <Grid key={title} size={{ xs: 12, sm: 6 }}>
              <Box
                sx={{
                  pt: '26px',
                  pb: '40px',
                  pl: '20px',
                  pr: '26px',
                  borderRadius: '10px',
                  bgcolor: 'rgba(223, 239, 249, 0.20)',
                  height: '100%',
                  maxWidth: { md: '300px', xs: 'none' },
                  float: index % 2 !== 0 ? 'right' : 'none',
                }}
              >
                <img
                  src={icon}
                  alt={`${title} icon`}
                  width={40}
                  height="auto"
                />
                <Typography
                  variant="body1"
                  fontSize="20px"
                  mt={'24px'}
                  mb={'28px'}
                >
                  {title}
                </Typography>
                <Typography variant="body1">{description}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default GetStarted;
