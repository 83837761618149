import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import platform from '@icon/platform.webp';
import building from '@icon/building.webp';
import { CONSOLE_URL } from '@variable';

const VALUES = [
  {
    title: 'Are you a business?',
    description: 'Start accepting crypto payments seamlessly.',
    icon: building,
    to: '/pricing',
    btnText: 'See Pricing',
  },
  {
    title: 'Are you a developer?',
    description: 'Integrate ZinariPay with your app effortlessly.',
    icon: platform,
    to: 'https://www.npmjs.com/package/zinari-pay',
    btnText: 'View documentation',
    target: '_blank',
  },
];

const ValueCard = ({ title, description, icon, btnText, to, target }: any) => {
  const navigate = useNavigate();

  return (
    <Box
      maxWidth={{ md: '300px', xs: 'none' }}
      sx={{
        px: 3,
        pt: 3,
        pb: 4,
        bgcolor: 'rgba(223, 239, 249, 0.30)',
      }}
    >
      <img
        src={icon}
        height={'auto'}
        width={43}
        alt="get started with zinari"
      />
      <Typography variant="h5" fontWeight={500} mt={3} mb={1}>
        {title}
      </Typography>
      <Typography mb={3}>{description}</Typography>
      <Button
        sx={{ p: 0 }}
        variant="text"
        href={target ? to : ''}
        target={target}
        onClick={() => {
          if (!target) navigate(to);
        }}
      >
        {btnText}
      </Button>
    </Box>
  );
};

const ReadyToGetStarted = () => {
  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        py: { lg: 15, xs: 5 },
        background: 'rgba(223, 239, 249, 0.20)',
      }}
    >
      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          maxWidth={{ md: '380px' }}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          <Typography variant="h4" fontWeight={700}>
            Ready to get started
          </Typography>
          <Typography mt={2} mb={3.5}>
            Join the thousands of businesses already using ZinariPay It&apos;s
            free to sign up.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            href={CONSOLE_URL}
            target={'_blank'}
          >
            Get Started
          </Button>
        </Box>

        <Stack direction={{ md: 'row', xs: 'column' }} spacing={3}>
          {VALUES.map((value) => (
            <ValueCard key={value.title} {...value} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReadyToGetStarted;
