import { Box, Stack, Typography } from '@mui/material';

import company from '@image/company.webp';

const Hero = () => {
  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      gap={2}
      sx={{
        height: { lg: '100vh', xs: 'fit-content' },
        maxHeight: { lg: '550px', xs: 'none' },
      }}
    >
      <Box
        sx={{
          pr: 2,
          pl: { lg: '130px', xs: 2 },
          maxWidth: { md: '730px', xs: 'none' },
        }}
      >
        <Typography variant="h2" fontWeight={700} textAlign="left" mb={5}>
          Zinari is here to offer an entirely new approach, empowering
          businesses.
        </Typography>
        <Typography lineHeight="27px" mb={10}>
          Our platform is designed to simplify transactions, making them faster,
          more secure, and cost-effective.
        </Typography>
      </Box>

      <Box
        width="fill-available"
        sx={{
          px: { xs: 2, lg: 0 },
          background: `url(${company}) no-repeat center center`,
          backgroundSize: 'contain',
          height: { xs: '300px', lg: '480px' },
        }}
      />
    </Stack>
  );
};

export default Hero;
