import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import image from '@image/pricing.webp';

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        py: { lg: 15, xs: 5 },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        gap={10.5}
      >
        <Box maxWidth={{ md: '515px', xs: 'none' }}>
          <img src={image} alt="zinaripay pricing" width="100%" height="auto" />
        </Box>

        <Box maxWidth={{ md: '580px', xs: 'none' }}>
          <Typography variant="h4" fontWeight={500}>
            Simple, scalable pricing designed to grow with your business.
          </Typography>
          <Typography variant="body1" mt={4} mb={5}>
            ZinariPay offers a simple, all-in-one pricing model that scales with
            your business. It&apos;s designed to provide flexible,
            cost-effective solutions for managing cryptocurrency transactions
            without hidden fees.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('/pricing')}
          >
            View Pricing
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Pricing;
