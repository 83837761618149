import { Box, Typography } from '@mui/material';
import React from 'react';

const Hero = () => {
  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        py: { md: 20, xs: 10 },
      }}
    >
      <Typography
        variant="h2"
        fontWeight={700}
        textAlign="center"
        sx={{ margin: 'auto', px: 1, mb: 4 }}
        maxWidth={{ md: '800px', xs: 'none' }}
      >
        Simple Pricing built for businesses of all sizes
      </Typography>
      <Typography
        textAlign="center"
        sx={{ margin: 'auto', px: 1, mb: 6 }}
        maxWidth={{ md: '550px', xs: 'none' }}
        color="textSecondary"
      >
        ZinariPay offers simple, transparent pricing for all businesses,
        ensuring no hidden fees and easy management of payment solutions.
      </Typography>
    </Box>
  );
};

export default Hero;
