import { Box } from '@mui/material';

import ReadyToGetStarted from '@component/ReadyToGetStarted';
import Helmet from '@component/Helmet';

import Hero from './components/Hero';
import Widgets from './components/Widgets';
import ContactSales from './components/ContactSales';

const Support = () => {
  return (
    <Box pt={{ md: 19, xs: 12 }}>
      <Helmet
        title="Contact Us"
        description="Get in touch with ZinariPay for any inquiries or support. We are here to assist you with seamless integration, secure transactions, and scalable payment solutions for your business."
        keywords="ZinariPay contact, customer support, cryptocurrency payment support, fintech inquiries, blockchain payment help"
      />

      <Hero />
      <Widgets />
      <ContactSales />
      <ReadyToGetStarted />
    </Box>
  );
};

export default Support;
