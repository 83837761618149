import React from 'react';
import { Box, Divider } from '@mui/material';

import TopSection from './TopSection';
import BottomSection from './BottomSection';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        pt: '120px',
        pb: '54px',
        bgcolor: 'primary.900',
        color: 'common.white',
        px: { lg: '130px', xs: 2 },
      }}
    >
      <TopSection />
      <Divider sx={{ my: 4, borderColor: 'common.white' }} />
      <BottomSection />
    </Box>
  );
};

export default Footer;
