import { Box } from '@mui/material';
import React from 'react';

import Helmet from '@component/Helmet';

import Content from './Content';

const Terms = () => {
  return (
    <Box>
      <Helmet
        title="Terms of Use"
        description="Read the detailed Terms and Conditions for using ZinariPay. Learn about our service rules, obligations, and your responsibilities while using our platform."
        keywords="Terms and Conditions, ZinariPay, user obligations, service terms, platform rules, legal agreement"
      />
      <Content />
    </Box>
  );
};

export default Terms;
