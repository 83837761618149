import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import safe from '@image/safe.webp';

const Safe = () => {
  return (
    <Box
      sx={{
        px: { lg: '130px', xs: 2 },
        py: { lg: 15, xs: 5 },
        background: 'rgba(223, 239, 249, 0.20)',
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        gap={10}
      >
        <Box maxWidth={{ md: '551px', xs: 'none' }}>
          <img src={safe} alt={'sale lock'} width="100%" height="auto" />
        </Box>
        <Box maxWidth={{ md: '577px', xs: 'none' }}>
          <Typography variant="h4" fontWeight={600}>
            Safe transactions
          </Typography>
          <Typography variant="body1" mt={3} mb={4}>
            ZinariPay makes crypto payments easy and safe. With strong security
            measures, your transactions are always protected, ensuring peace of
            mind for both personal and business payments. ZinariPay guarantees
            reliable, secure processing every time.
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            href="mailto:sales@zinari.io"
          >
            Contact Sales
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Safe;
