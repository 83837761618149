import { Stack } from '@mui/material';
import React from 'react';

import Fees from './Fees';

const PRICING = [
  { fee: '1 %', description: 'Nigerian companies' },
  { fee: '3 %', description: 'Others' },
];

const FeeSection = () => {
  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      px={{ lg: '130px', xs: 2 }}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      mb={7}
    >
      {PRICING.map((value) => (
        <Fees {...value} key={value.description} />
      ))}
    </Stack>
  );
};

export default FeeSection;
